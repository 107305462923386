// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-carbon-test-js": () => import("./../../../src/pages/carbonTest.js" /* webpackChunkName: "component---src-pages-carbon-test-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-discover-js": () => import("./../../../src/pages/discover.js" /* webpackChunkName: "component---src-pages-discover-js" */),
  "component---src-pages-downloads-js": () => import("./../../../src/pages/downloads.js" /* webpackChunkName: "component---src-pages-downloads-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-2-js": () => import("./../../../src/pages/index2.js" /* webpackChunkName: "component---src-pages-index-2-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-press-releases-js": () => import("./../../../src/pages/press-releases.js" /* webpackChunkName: "component---src-pages-press-releases-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-register-machine-js": () => import("./../../../src/pages/register-machine.js" /* webpackChunkName: "component---src-pages-register-machine-js" */),
  "component---src-pages-request-brochure-js": () => import("./../../../src/pages/request-brochure.js" /* webpackChunkName: "component---src-pages-request-brochure-js" */),
  "component---src-pages-subscribe-js": () => import("./../../../src/pages/subscribe.js" /* webpackChunkName: "component---src-pages-subscribe-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-tomcat-difference-js": () => import("./../../../src/pages/tomcat-difference.js" /* webpackChunkName: "component---src-pages-tomcat-difference-js" */),
  "component---src-pages-tomcat-team-js": () => import("./../../../src/pages/tomcat-team.js" /* webpackChunkName: "component---src-pages-tomcat-team-js" */),
  "component---src-pages-warranty-js": () => import("./../../../src/pages/warranty.js" /* webpackChunkName: "component---src-pages-warranty-js" */),
  "component---src-templates-product-features-js": () => import("./../../../src/templates/ProductFeatures.js" /* webpackChunkName: "component---src-templates-product-features-js" */),
  "component---src-templates-product-gallery-js": () => import("./../../../src/templates/ProductGallery.js" /* webpackChunkName: "component---src-templates-product-gallery-js" */),
  "component---src-templates-product-overview-js": () => import("./../../../src/templates/ProductOverview.js" /* webpackChunkName: "component---src-templates-product-overview-js" */),
  "component---src-templates-product-specifications-js": () => import("./../../../src/templates/ProductSpecifications.js" /* webpackChunkName: "component---src-templates-product-specifications-js" */),
  "component---src-templates-product-support-js": () => import("./../../../src/templates/ProductSupport.js" /* webpackChunkName: "component---src-templates-product-support-js" */)
}

